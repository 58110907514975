.ts-component-single-select .dropdown {
    height: auto;
    min-height: 33px;
}

.ts-component-single-select .value-text {
    width: 180px;
}

.ui.dropdown .menu>* {
    white-space: normal;
}

.ts-component-single-select .dropdown .text{
    line-height: 1.2em;
}