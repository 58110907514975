.ts-component-accordion-container .panel-default {
  border-color: inherit;
}

.ts-component-accordion-container .panel {
  margin-bottom: 0;
  background-color: inherit;
  border: none;
  border-radius: inherit;
  box-shadow: none;
}

.ts-component-accordion-container .panel-body {
  margin: 0;
  padding: 12px 0 0;
  display: flex;
  flex-direction: column;
}

.ts-component-accordion-container {
  width: 346px;
  border: 1px solid #BECBE0; 
  border-radius: 5px;
  padding: 12px 15px 12px 15px;
  margin-bottom: 15px;
  transition: 0.2s;
}



.ts-component-accordion-container.open {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.03);
}

.ts-component-accordion-container-title {
  font-size: 17px;
  font-weight: bold;
  color: #222f2d;
  letter-spacing: 0.21px;
  line-height: 21px;
}

.ts-component-accordion-container-title:hover {
  cursor: pointer;
}
