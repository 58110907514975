.v4 .SingleDatePicker .SingleDatePickerInput.SingleDatePickerInput__withBorder {
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #B6C8DA;
  height: 36px;
  margin-left: 5px;
}
.v4 .SingleDatePicker .SingleDatePickerInput__withBorder .DateInput {
  background: transparent;
}
.v4 .SingleDatePicker .SingleDatePickerInput__withBorder .DateInput .DateInput_input {
  background: transparent;
  height: 36px;
}
.v4 .SingleDatePickerInput #from-date.DateInput_input, .v4 .SingleDatePickerInput #end-date.DateInput_input {
  font-size: 13px;
}
.date-filter-label-v4 {
  display: inline-block;
  margin-left: 5px;
  margin-top: 9px;
  font-size: 13px;
}

.date-filter-label {
  display: inline-block;
  margin: 5px;
}