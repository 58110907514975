.ts-component-multi-select.up .faux-placeholder {
  color: #D8D8D8
}

.score-container {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding-right: 20px;
}

.arrow-right-down-green {
  display: flex;
  background-image: url('../../../../Icons/arrow-right-down-green.svg');
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  height: 10px;
  outline: none;
}

.arrow-right-up-red {
  display: flex;
  background-image: url('../../../../Icons/arrow-right-up-red.svg');
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center;
  height: 10px;
  outline: none;
}

.outcome-measures-icon {
  display: flex;
  background-image: url('../../../../Icons/outcome-measure-icon.svg');
  background-size: 62px 70px;
  background-repeat: no-repeat;
  background-position: top;
  height: 70px;
  outline: none;
  padding: 10px;
}

.outcome-measure-text {
  display: flex;
  text-align: right;
  color: #ccc;
  font-size: 15px;
  font-weight: 500;
}

.outcome-measures-container.ts-component-accordion-container .panel-body{
  padding-top: 2px;
}

.om-answer-row {
  display: flex;
  flex-direction: row;
  margin: 0 5px;
  padding: 6px 0;
  min-height: 66px;
  font-size: 13px;
  border-bottom: 1px solid #e1e1e1;
}

.om-answer-row .right-content {
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.om-answer-row .question {
  margin: 3px 0;
}
.om-answer-row .answer {
  color: #888;
  margin: 3px 0;
}

.om-answer-row .order-number {
  font-size: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.om-cancel .om-cancel-msg {
  margin-bottom: 35px;
}
