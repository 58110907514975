.user-action-card {
  max-width: 344px;
  min-width: 344px;
  padding: 15px 16px 15px 16px;
  width: 344px;
  border-radius: 5px;
  border: 1px solid #C4C4C4;
  font-family: 'roboto';
  margin-bottom: 25px;
  display: inline-block;
  transition: 0.4s;
  font-family: 'Roboto', sans-serif;
}

.user-action-card .content {
  padding: 12px 3px 22px 3px;
  color: #747474;
  font-size: 14px;
}

.user-action-card .name {
  padding-left: 6px;
  padding-top: 8px;
  font-size: 15px;
  font-weight: bold;
}

.user-action-card .card-button {
  padding-right: 0px;
  margin-bottom: 11px;
  text-align: right;
  font-size: 15px;
  color: #0FC1A7;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 20px;
  width: auto;
  float: right;
}

.user-action-card .card-button:hover {
  cursor: pointer;
}

.user-action-card .card-button.secondary {
  color: #C4C4C4;
}
