.treatment-plan-checklist-container {
  margin-bottom: 87px;
}

.treatment-planner-multiselect {
  margin-top: 15px;
  margin-bottom: 20px;
}

.treatment-planner-multiselect .ui.label {
  background-color: #5C84FB !important;
}

.treatment-planner-multiselect .ts-component-multi-select .ui.label.grayed {
  background-color: #C7C7C7 !important;
  pointer-events: none;
}

.treatment-planner-multiselect .ts-component-multi-select .ui.label.grayed .delete:before {
  border-color: #fff !important;
  color: #fff !important;
}

.treatment-plan-goal {
  padding: 6px !important;
}

.treatment-planner-intro-text {
  margin-top: 7px;
  color: #747474;
}

.treatment-plan-checklist-container .checklist-item label {
  color: #747474;
}

.treatment-planner-multiselect .ui.multiple .menu {
  width: 296px !important;
  min-width: unset !important;
  word-wrap: break-word;
  white-space: normal;
}

.treatment-planner-multiselect .ui.multiple .menu .item span {
  word-wrap: break-word;
  white-space: normal;
}

.ts-component-alert-container-child-components {
  padding-top: 10px;
  margin-right: -16px;
}