.room {
  float: left;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex: 1;
  width: 375px;
  background-color: white;
  border-right: 0.5px solid #d3d3d3;
}

.iframe .room {
  height: calc(100vh);
  width: 321px;
}

.iframe .messagesContainer {
  display: none;
}

input,
textarea {
  caret-color: #005C65;
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .room {
    width: 100%;
  }

  .iframe .room {
    display: block;
  }
}

/** 
 * iPad with portrait orientation.
 */
@media all and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .room-route .crm-room .room-journey-tab {
    height: 640px !important;
    -webkit-overflow-scrolling: touch;
  }
  .room-route .messagesContainer iframe {
    height: 887px !important;
  }
  .notes-tab .notes-container {
    -webkit-overflow-scrolling: touch;
  }
  .ts-component-footer {
    bottom: 30px;
  }
}

/** 
 * iPad with landscape orientation.
 */
@media all and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .room-route .crm-room .room-journey-tab {
    height: 384px;
    -webkit-overflow-scrolling: touch;
  }
  .room-route .messagesContainer iframe {
    height: 631px !important;
  }
  .notes-tab .notes-container {
    -webkit-overflow-scrolling: touch;
  }
  .ts-component-footer {
    bottom: 30px;
  }
}
