.fc-day-today {
  background-color: inherit !important;
}



.fc .fc-toolbar.fc-header-toolbar{
  margin: 0
}

.fc col{
  background: white;
}

.fc-scrollgrid {
  border: none !important;
}

.fc-scrollgrid td {
  border-color: #C8D2DE;
}

.fc-scrollgrid td:last-of-type {
  border-right: none !important;

}

.v2.fc-bg-event {
  opacity: 1 !important;
  outline: none;
}

.fc-media-screen .fc-timegrid-now-indicator-container {
  position: unset;
}

.fc .fc-timegrid-now-indicator-line.v2 {
  border-bottom-width: 1px; 
  border-top-width: 1px;
  border-color: #EA4535;
  background-color: #EA4535;
}


.fc-timeGridDay-view  .fc-timegrid-now-indicator-arrow.v2 {
  position: absolute;
  border: none;
  left: unset;
  right: -6px;
  width: 12px;
  height: 12px;
  background-color: #EA4535;
  border-radius: 50%;
}
 

.fc-timegrid-now-indicator-arrow.v2 {
  display: none;
}

.fc-timegrid-now-indicator-line.v2::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  background-color: #EA4535;
  border-radius:50%;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event{
  box-shadow: none;
}