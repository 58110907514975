.item-list-head {
  font-weight: bold;
  margin-top: 10px;
  color: #d3d3d3;
}

.item-list-no-data {
  font-weight: bold;
  color: #b1b1b1;
  margin-top: 10px;
  order: 1;
}

.item-list-container,
.item-list-head,
.item-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item-list,
.item-list-head {
  align-items: flex-start;
}

.item-list-container .item-list {
  flex-direction: column;
}

.item-list-head {
  flex-direction: row;
  align-self: stretch;
}