.therapist-navbar .dropdown-menu {
  display: block !important;
  font-size: 15px;
  font-weight: 500;
}

.navbar-profile-img {
  border: none;
  padding: 0;
  width: 33px;
  height: 33px;
  margin-left: 8px;
  border-radius: 50%;
  filter: brightness(95%);
}

.profile-btn {
  margin-top: 5px;
  background: none;
  border: none;
}

.profile-dropdown,
.profile-dropdown:hover {
  background: none;
  border: none;
  color: #fff;
  font-size: 15px;
  padding: 1em;
}

.navbar-right {
  display: flex;
  align-items: center;
  /* Undo bootstrap CSS for navbar-right */
  float: none !important;
  margin-right: 0px !important;
}

.navbar-right .dot {
  height: 11px;
  width: 11px;
  border-radius: 5.5px;
  margin: 0 0 1px 6px;
}

.open > .dropdown-toggle.btn-default,
.open > .profile-dropdown:focus,
.open > .profile-dropdown:hover,
.open > .profile-dropdown:active,
.profile-dropdown:focus,
.profile-dropdown:hover,
.profile-dropdown:active {
  background-color: #009b8d !important;
  border-radius: 0;
}
.navbar-right .dropdown-menu {
  width: 293px;
  top: 60px;
  right: 22px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #eff3fa !important;
  -webkit-box-shadow: 0px 4px 10px -4px rgba(0, 40, 65, 0.4); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 4px 10px -4px rgba(0, 40, 65, 0.4); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 4px 10px -4px rgba(0, 40, 65, 0.4);
}

.navbar-right .my-account-link {
  display: flex;
  align-items: center;
}

.navbar-right .collapsible {
  width: 0;
}

.navbar-left .full-logo {
  display: block;
}

.navbar-left .collapsed-logo {
  display: none;
}

.navbar-right .dropdown-menu > .button {
  padding: 8px 21px 10px !important;
  margin: 5px 9px !important;
  border-radius: 5px;
  cursor: pointer;
}

.navbar-right .dropdown-menu > .button:hover {
  background: #eff3fa;
}

.therapist-navbar .container {
  flex-grow: 1;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  position: relative;
}

.therapist-navbar a {
  color: #000;
}

.therapist-navbar a:hover {
  color: #000;
}

.therapist-navbar .top-collapse-nav a {
  font-weight: 600;
}

.therapist-navbar .top-collapse-nav a:hover {
  color: #0fc1a7;
}

.therapist-navbar .dropdown > button {
  border: none;
  font-weight: 600;
  height: 30px;
  padding: unset;
}

span.caret {
  display: none;
}

.therapist-navbar .dropdown > button:hover,
.therapist-navbar .dropdown > button:active,
.therapist-navbar .dropdown > button:hover,
.therapist-navbar .dropdown > .btn-default.active,
.therapist-navbar .dropdown > .btn-default.hover,
.therapist-navbar .dropdown.open > .btn-default {
  color: #0fc1a7;
  background-color: unset !important;
  box-shadow: unset;
}

.therapist-navbar .dropdown > .btn-default.focus,
.therapist-navbar .dropdown > button:focus {
  background-color: unset !important;
  box-shadow: unset;
}

.therapist-navbar .container,
.therapist-navbar .container .navbar-collapse,
.therapist-navbar .container .navbar-collapse .drowpdown-container {
  display: flex !important;
  flex-direction: row;
}

.therapist-navbar .container .navbar-collapse,
.therapist-navbar .container .navbar-collapse .drowpdown-container {
  justify-content: flex-end;
  align-items: center;
}

.navbar-default .navbar-nav > li:last-child > a {
  padding-right: 0px;
}

.navbar-collapse {
  padding-right: 0px;
}

.therapist-navbar .container .navbar-collapse {
  flex-grow: 1;
}

.therapist-dropdown-container ul {
  margin: 2px -104px 10px;
  min-width: 190px;
}

.therapist-dropdown-container > a {
  margin-right: 15px !important;
}

.therapist-dropdown-container:hover span {
  color: #0fc1a7;
}

.mobile-wrapper .mobile-header {
  display: none;
}

.navbar-right .my-account-link .btn-group {
  /* background: #fff; */
  color: '#7D8993';
  font-size: 13px;
}

@media only screen and (max-width: 1199px) {
  .therapist-dropdown-container ul {
    margin: 2px -70px 10px;
  }
}

.dropdown-menu.availability-dropdown {
  right: 195px;
  width: 170px;
}
.availability-button {
  outline: none;
  height: 35px;
  border: 1px solid #eee;
  border-radius: 17.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-right: 30px;
  outline: none;
  cursor: pointer;
  transition: width 0.6s ease-in-out;
  width: auto;
}
.availability-btn-span {
  color: #65727d;
  font-weight: 500;
  text-align: right;
}

@media (max-width: 767px) {
  .navbar-left .full-logo {
    display: none;
  }

  .navbar-left .collapsed-logo {
    display: block;
  }

  .therapist-navbar .mobile-wrapper {
    left: 0;
    right: 0;
    height: calc(100vh - 67px);
    background: white;
    width: 100vw;
    top: 67px;
    position: fixed;
    z-index: 5;
    overflow: hidden;
  }

  .therapist-navbar .mobile-navbar {
    position: fixed;
    height: 67px;
    top: 0;
    background: #fff;
    width: 100vw;
    left: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 6;
  }

  .therapist-navbar .mobile-navbar .arrow-left-container {
    width: 40px;
    margin: 25px 0 0 6px;
    padding: 10px;
  }

  .navbar-right .dropdown-menu {
    width: calc(100vw - 40px);
    margin: 20px;
    top: 10px;
    box-shadow: none;
    padding-bottom: 30px;
  }

  .navbar-right .dropdown-menu::before,
  .navbar-right .dropdown-menu::after {
    display: none;
    content: none;
  }

  .therapist-navbar .mobile-wrapper .mobile-header {
    display: block;
    font-weight: 600;
    font-size: 17px;
    margin: 15px 0 13px 20px;
  }

  .therapist-navbar .my-account-link {
    padding-bottom: 10px;
    background: url('../../Icons/arrow-right.svg') no-repeat right center;
    background-size: 8px;
  }

  .therapist-navbar .navbar-header .button {
    border: none;
    font-weight: 600;
    height: 70px;
    padding: 5px 0px 0px 0px;
    margin: 0px 15px 0px 15px;
    cursor: pointer;
  }

  .navbar-right .dropdown-menu > .button {
    padding: 7px 13px 0 13px !important;
  }

  .navbar-right .my-account-link {
    padding-top: 5px;
    background-position: 100% 40%;
    padding-right: 20px;
  }

  .therapist-navbar a,
  .therapist-navbar a span {
    color: #000;
    font-weight: 600;
  }

  .navbar-default .navbar-nav > li > a {
    color: #000;
  }

  .therapist-navbar .navbar-header button:hover,
  .therapist-navbar .navbar-header button:active,
  .therapist-navbar .navbar-header .btn-default.active,
  .therapist-navbar .navbar-header .btn-default.hover,
  .therapist-navbar a span:hover,
  .therapist-navbar a span:active,
  .navbar-default .navbar-nav > li > a:hover,
  .therapist-navbar .navbar-header .btn-default.focus,
  .therapist-navbar .navbar-header button:focus {
    color: #000;
    background-color: unset !important;
    box-shadow: unset;
  }

  .therapist-navbar .container,
  .therapist-navbar .container .navbar-collapse,
  .therapist-navbar .container .navbar-collapse .drowpdown-container {
    display: block !important;
    flex-direction: unset;
  }

  .therapist-navbar .container .navbar-collapse,
  .therapist-navbar .container .navbar-collapse .drowpdown-container {
    align-self: unset;
    justify-content: unset;
    align-items: unset;
  }

  .drowpdown-container a {
    height: 55px;
  }
  .therapist-navbar .container .navbar-collapse {
    flex-grow: unset;
  }
}
