.date-time-picker input:active, .date-time-picker input:focus {
    border-bottom: 1px solid #0FC1A7;
}

.date-time-picker .react-datepicker .react-datepicker__time-container .react-datepicker__header.react-datepicker__header--time {
    padding-bottom: 0.2em;
    padding-top: 2.6em;
}

.date-time-picker .react-datepicker .react-datepicker__header {
    background-color: #f0f0f0;
    border-radius: unset;
}

.react-datepicker__current-month, .react-datepicker-time__header {
    font-size: 0.8em;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background-color: #0FC1A7;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
    background-color: #0FC1A7;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #0FC1A7;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #0FC1A7;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 5px 0px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding-right: 0px;
    padding-left: 0;
}

.react-datepicker {
    font-size: 1em !important
}

.react-datepicker__month {
    margin: 0.4em 1em !important
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}