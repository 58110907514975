.messagesContainer iframe {
  float: left;
  width: calc(100vw - 375px);
  height: calc(100vh - 67px);
  border: none;
  overflow-y: scroll;

}


@media only screen and (max-width: 767px) {
  .messagesContainer iframe {
    width: 100vw;
  }
}
