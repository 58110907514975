.reaction-button {
  color: #8e99a6;
  font-size: 12px;
  flex-grow: 1;
  margin: 10px 0px 10px 25px;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: unset;
  box-shadow: unset;
  border: unset;
  width: 30px;
  background-repeat: no-repeat;
}
.reaction-button:focus {
  outline: none;
}
.reaction-button:last-child {
  margin-right: 25px;
}
