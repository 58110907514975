.ts-component-submenu-child .list-body {
  margin: 0 0 30px 10px;
}

.ts-component-submenu-child .list-header {
  font-weight: 600;
  font-size: 15px;
  margin: 10px 0;
}

