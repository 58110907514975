.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}
.checkbox label::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 4px;
  padding-top: 3px;
  font-size: 12px;
  color: #555555;
}
.checkbox input[type='checkbox'],
.checkbox input[type='radio'] {
  opacity: 0;
  z-index: 1;
}
.checkbox input[type='checkbox']:focus + label::before,
.checkbox input[type='radio']:focus + label::before {
}
.checkbox input[type='checkbox']:checked + label::after,
.checkbox input[type='radio']:checked + label::after {
  font-family: 'FontAwesome';
  content: '\f00c';
}
.checkbox input[type='checkbox']:indeterminate + label::after,
.checkbox input[type='radio']:indeterminate + label::after {
  display: block;
  content: '';
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}
.checkbox input[type='checkbox']:disabled + label,
.checkbox input[type='radio']:disabled + label {
  opacity: 0.65;
}
.checkbox input[type='checkbox']:disabled + label::before,
.checkbox input[type='radio']:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-green input[type='checkbox']:checked + label::before,
.checkbox-green input[type='radio']:checked + label::before {
  background-color: #007e73;
  border-color: #007e73;
}
.checkbox-green input[type='checkbox']:checked + label::after,
.checkbox-green input[type='radio']:checked + label::after {
  color: #fff;
}

.checkbox-green input[type='checkbox']:indeterminate + label::before,
.checkbox-green input[type='radio']:indeterminate + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}

.checkbox-green input[type='checkbox']:indeterminate + label::after,
.checkbox-green input[type='radio']:indeterminate + label::after {
  background-color: #fff;
}
.checkbox-green input[type='checkbox']:disabled + label::before,
.checkbox-green input[type='radio']:disabled + label::before {
  opacity: 1;
  background-color: rgb(204, 204, 204);
  border-color: rgb(204, 204, 204);
}
.w9-text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #8b8a8a;
  letter-spacing: -0.15px;
  font-weight: 400;
}
