.card-button {
  margin-right: 22px;
  text-align: right;
  font-size: 15px;
  color: #007e73;
  font-weight: 500;
  letter-spacing: 0.42px;
  line-height: 20px;
  width: auto;
  float: right;
}

.ts-component-accordion-container .card-button {
  margin-right: 0;
}

.card-button:hover {
  cursor: pointer;
}

.card-button.secondary {
  color: #c4c4c4;
}
