.ts-panel-title {
  padding-top: 10px;
  padding-bottom: 2px;
}

.ts-font-black {
  color: #222f2d;
  font-weight: 900 !important;
}

.btn-ts-default {
  background-color: transparent;
  border: 1px solid #007e73;
  border-radius: 6px;
}

.btn-ts-default.focus,
.btn-ts-default:focus,
.btn-ts-default:hover {
  color: #fff;
  background-color: #009b8d;
  border-color: #009b8d;
}

.fa-question-circle {
  color: #007e73;
}

.btn[disabled] {
  background-color: #dcdcdc;
  border-color: #dcdcdc;
}

.btn[disabled]:hover {
  background-color: #dcdcdc;
}

.ts-profile-btn-text-green,
.ts-profile-text-green {
  color: #09b198 !important;
  letter-spacing: -0.17px;
}

.ts-profile-btn-text-green.focus,
.ts-profile-btn-text-green:focus,
.ts-profile-btn-text-green:hover {
  color: #fff !important;
}

.btn-ts-green {
  color: #fff;
  background-color: #007e73;
  border-color: #007e73;
  border-radius: 6px;
}

.ts-profile-text-white {
  color: #fff;
  letter-spacing: -0.11px;
  font-weight: 400;
}

.cancel-btn {
  margin-right: 5px;
}

.ts-edit-button {
  background: none;
  border: none;
  margin-right: 10px;
  color: #007e73;
  font-size: 16px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.ts-edit-button.focus,
.ts-edit-button:focus,
.ts-edit-button:hover {
  color: #23527c;
  text-decoration: underline;
  background: none;
}

.private-information-input-label {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-size: 12px;
  color: #b8b8b8;
  letter-spacing: -0.15px;
  font-weight: 400;
}

.clinical-information-text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: -0.15px;
  font-weight: 400;
}
