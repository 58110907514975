.room-journey-tab .card {
  border-radius: 9px;
  box-shadow: 0px 4px 10px rgba(0, 29, 47, 0.15);
  border: 0.5px solid #e3e3e3;
  transition: all 0.5s ease-out !important;
  height: auto;
  margin-top: 0;
}

.room-journey-tab .card.dismissing {
  height: 0 !important;
  margin: 0;
  transition: all 0.5s ease-out !important;
  opacity: 0;
}

.room-journey-tab .card.dismissed {
  display: none;
}

.fa-angle-right {
  margin-left: 5px;
}

.tips-and-techniques-section {
  padding: 0;
  width: 375px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  max-height: 450px;
  border-top: 1px solid #d3d3d3;
  padding-bottom: 76px;
  border-right: 1px solid #a8a8a8;
}

.tips-and-techniques-section .panel-default {
  border-color: none;
}

.tips-and-techniques-section .panel {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.tip-container .panel-body {
  margin: 0 20px 0 0;
  background-color: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.tips-card-container {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: none;
  padding-top: 0;
  width: 345px;
}

.room-journey-tab {
  position: relative;
  font-family: 'Roboto', sans-serif;
  padding: 15px;
  overflow-y: auto;
}

.room-journey-tab {
  cursor: default;
}

.tips-title {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.14);
  transition: box-shadow 0.5s ease;
}

.tips-title {
  height: 48px;
  background-color: #ffffff;
  font-size: 19px;
  line-height: 25px;
  padding: 13px;
  font-weight: bold;
  text-align: center;
}

.tips-and-techniques-mobile-section .tips-title {
  height: 21px;
}

.no-items-icon {
  background: url('TipsAndTools/notipsicon.png') no-repeat center;
  margin-top: calc(50vh - 250px);
  height: 150px;
}

.no-items-text {
  color: #b8b8b8;
  text-align: center;
  font-size: 19px;
}

.tips-card-container-dismissed {
  padding-top: 10px;
}

.tip-container {
  background-color: #ffffff;
  margin: 0 0 9px 0;
}

.tip-heading {
  padding: 20px 20px 2px 20px;
  display: flex;
}

.tip-icon {
  background: url('TipsAndTools/tip-icon2x.png') no-repeat center;
  background-size: 20px;
  width: 22px;
  height: 22px;
  padding: 5px;
}

.tip-icon.tip {
  background: url('TipsAndTools/tip.svg') no-repeat center;
}

.tip-icon.technique {
  background: url('TipsAndTools/technique.svg') no-repeat center;
}

.tip-icon.to-do {
  background: url('TipsAndTools/to-do.svg') no-repeat center;
}

.tip-icon.insight {
  background: url('TipsAndTools/insight.svg') no-repeat center;
}

.tip-icon.alert {
  background: url('TipsAndTools/alert.svg') no-repeat center;
}

.tips-title,
.mobile-tips-title {
  margin-bottom: 0;
}

.tip-title {
  margin-left: 18px;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  width: 285px;
}

.tip-description {
  margin-left: 60px;
  margin-right: 20px;
  font-size: 14px;
  line-height: 18px;
  transition: height 0.6s ease;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #454A5E;
}

.tip-description-expanded {
  padding-left: 60px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 18px;
  height: inherit;
  overflow: hidden;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.tip-container .action-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 14px;
  margin-bottom: 14px;
}

.tip-description-expanded div {
  margin-top: -54px;
}

.dismiss-tip-btn {
  margin-top: 0;
  padding: 0 21px 15px 21px;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  color: #b8b8b8;
  text-align: right;
}

.confirmation-box-mini {
  display: inline-block;
  float: right;
  padding-right: 12px;
}

.dismiss-tip-btn:hover,
.tip-expand-down:hover,
.tip-expand-up:hover,
.show-tips-mobile:hover {
  cursor: pointer;
}

.tip-expand-down {
  background: url('TipsAndTools/angle-up2x.png') no-repeat center;
  background-size: 12px;
  width: 17px;
  height: 18px;
  margin-left: auto;
  transition: 0.3s;
}

.tip-expand-up {
  background: url('TipsAndTools/angle-up2x.png') no-repeat center;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  background-size: 12px;
  transition: 0.3s;
  width: 17px;
  height: 18px;
  margin-left: auto;
}

.show-tips-mobile {
  background: url('TipsAndTools/angle-down2x.png') no-repeat center;
  background-size: 17px;
  width: 17px;
  height: 18px;
  position: absolute;
  top: 82px;
  left: 9.6px;
  z-index: 1;
}

.show-tips-mobile.collapsed {
  background: url('TipsAndTools/angle-up2x.png') no-repeat center;
  background-size: 17px;
  width: 17px;
  height: 18px;
}

.tips-collapse-toggle {
  background: url('TipsAndTools/angle-down2x.png') no-repeat;
  background-position: 15px 20px;
  background-size: 12px;
  position: absolute;
  height: 44px;
  display: block;
  bottom: 379px;
  left: 0;
  position: absolute;
  width: 44px;
  background-color: #fff;
  border-right: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  transition: bottom 0.3s ease-out;
}

.tips-collapse-toggle.tips-collapsed {
  background: url('TipsAndTools/angle-up2x.png') no-repeat center;
  background-size: 12px;
  width: 43px;
  height: 46px;
  bottom: 0;
  background-color: #fff;
}

.tips-card-container-dismissed {
  border-right: none;
}

.mobile-tips-title {
  display: block;
  height: 26px;
  background: #ffffff;
  margin: 0;
}

.tips-title {
  display: none;
}

.tips-and-techniques-mobile-section .tip-container {
  width: 354px;
}

.tips-and-techniques-mobile-section .tip-title {
  width: 285px;
}

.tips-and-techniques-mobile-section .dismiss-tip-btn {
  padding-left: 12px;
}

.tips-and-techniques-mobile-section .tip-container {
  width: 279px;
}

.tips-and-techniques-mobile-section .tip-title {
  width: 211px;
}

.tips-and-techniques-mobile-section .dismiss-tip-btn {
  padding-left: 12px;
}

.tips-and-techniques-mobile-section .dismiss-tip-btn {
  padding-left: 12px;
}

.room-journey-tab > .error-message {
  font-size: 15px;
  font-weight: 500;
  color: #c4c4c4;
  margin-left: 8px;
}

.room-journey-tab::-webkit-scrollbar {
  visibility: hidden;
}
.room-journey-tab::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.room-journey-tab::-webkit-scrollbar-track {
  visibility: hidden;
}
.room-journey-tab:hover::-webkit-scrollbar {
  visibility: visible;
}
.room-journey-tab:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.room-journey-tab:hover::-webkit-scrollbar-track {
  visibility: visible;
}
.alert{
  margin-bottom: 4px;
}