.create-new-treatment-plan.treatment-plan-card-container {
  border: 2px solid #E8006B;
  border-radius: 8px;
  padding: 20px 20px 40px 20px;
}

.create-new-treatment-plan .treatment-plan-card-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.create-new-treatment-plan .treatment-plan-card-title i {
  background-color: #E8006B;
  padding-top: 4px;
  padding-bottom: 2px;
  border-radius: 6px;
  color: #FFF;
  margin-right: 5px;
}

.create-new-treatment-plan .treatment-plan-card-content {
  font-size: 14px;
  color: #747474;
  margin-bottom: 10px;
}

.create-new-treatment-plan .card-button {
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 22px;
  margin-right: 0px;
}
