.ts-spinner-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ts-spinner-container .message {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.2px;
  margin-left: 10px;
}

.ts-spinner {
  background: url('./spinning.png') right;
  background-size: auto 32px;
  height: 32px;
  width: 32px;
  margin: 0 10px;
}

.ts-spinner.success {
  display: flex;
  background: url('./success.png') right;
  background-size: auto 32px;
  height: 32px;
  width: 32px;
}

.ts-spinner.error {
  display: flex;
  background: url('./error.png') right;
  background-size: auto 32px;
  background-position-x: 64px;
  height: 32px;
  width: 32px;
}

.ts-component-footer-child .ts-spinner-container {
  width: 350px;
}
