.list-item {
  padding: 15px 0px;
  font-weight: 400;
  color: #333;
  border-top: 1px solid #d3d3d3;
}

.list-item.grayed {
  color: #b1b1b1;
}

.list-item:first-child {
  border-top: none;
}

.list-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  align-self: stretch;
}

.list-item > div {
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
}

.list-item > div.list-item-dropdown {
  overflow: unset;
}

.outcome-measures-container .list-item > div {
  overflow: hidden;
}

.list-item-dropdown  .btn-link {
  color: #b1b1b1;
  padding-top: 0px;
}
.list-item-dropdown .open .btn-link {
  color: #0FC1A7;
}

.list-item-dropdown .dropdown-menu {
  top: -9px;
  right: 25px;
  left: unset;
  border-radius: 10px;
}

.list-item-dropdown .dropdown-menu li.danger a {
  color: #E8006B;
  font-weight: bold;
}

.list-item-dropdown .dropdown-menu li.default a {
  color: #000000;
  font-weight: bold;
}

.list-item-dropdown .dropdown-menu li.success a {
  color: #0FC1A7;
  font-weight: bold;
}

.list-item-icon {
  font-size: 16px;
}

.list-item-icon.success {
  color: #0FC1A7;
}

.dropdown-menu>li>a:hover {
  background-color: transparent !important;
}

