h4 {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.new-room-row,
.all-room-row,
.live-sessions-row {
  background-color: rgba(231, 248, 246, 0.6);
}

.white-row {
  background-color: #ffffff;
}

.new-room-row,
.all-room-row:hover {
  cursor: pointer;
}

.client-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 68px);
}

.client-wrapper-padding {
  padding: 0px 35px;
}

.client-wrapper-with-metrics {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 140px);
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
}

.table {
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.new-clients-row {
  background-color: #f9fafb;
  border-bottom: 8px;
}

.new-clients-heading {
  padding: 0.92857143em 0.78571429em !important;
  border-bottom: none !important;
}

.tin-hidden {
  margin-top: 10px !important;
}

.selectedDaysOfWeek {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  font-size: 11px;
}

.room_id {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  font-size: 11px;
}

.user_id {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  font-size: 11px;
}

.room-reminder-text > a {
  position: initial !important;
  display: initial !important;
  font-family: 'Helvetica';
}

.rrCircleRed {
  display: inline-block;
  padding: 5px 6px;
  font-size: 90%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 50% 50%;
  background-color: #e10047;
}

.row-within-rows {
  padding: 0.785714em !important;
  line-height: 2 !important;
  vertical-align: middle !important;
}

.react-bs-table table td {
  white-space: normal !important;
  word-wrap: break-word !important;
  background-color: rgba(231, 248, 246, 0.6);
}

.react-bs-table-search-form {
  margin-bottom: 1em !important;
}

.xno-display {
  display: none;
}

.table-bordered > tbody > tr > td {
  border: none !important;
}

.new-room-row,
.all-room-row {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.number-indication {
  margin-left: 6px;
  background-color: #5cd6c5;
  border-radius: 4px;
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  line-height: 8px;
  min-width: 12px;
  padding: 4px;
  display: inline-block;
  margin-top: -2px;
  vertical-align: middle;
}

.button-rows {
  padding-bottom: 1em;
}

.react-bs-table table th {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.table-header {
  border: 0px !important;
  background-color: white;
  border-bottom-width: 0px !important;
  width: 10%;
}
.new-room-row,
.all-room-row:hover {
  cursor: pointer;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
}

.table {
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.selectedDaysOfWeek {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  font-size: 11px;
}

.tdCircleRed {
  margin: 0 auto;
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%; /* 50% == circle */
  position: relative;
  background-color: #ff0000;
  border-color: #ff0000;
}
.tdCircleYellow {
  margin: 0 auto;
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%; /* 50% == circle */
  position: relative;
  background-color: #ffff00;
  border-color: #ffff00;
}
.tdCircleGreen {
  margin: 0 auto;
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%; /* 50% == circle */
  position: relative;
  background-color: #00ff00;
  border-color: #00ff00;
}

.client-plan-weekdays,
.client-renews-date-mobile,
.client-source-title,
.client-overdue-mobile {
  display: none;
}

.client-renews-date,
.client-overdue {
  display: inline-block;
}

.client-overdue,
.client-overdue-mobile {
  font-size: 13px;
}

.room-arrow {
  display: none;
}

.engagement-circle {
  display: none;
}

.funky-yellow {
  background-color: rgba(255, 255, 0, 0.6) !important;
}

.follow-up-group-header {
  background-color: #0fc1a7 !important;
  color: #fff;
  font-size: 18px;
  font-family: Roboto, serif;
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  font-variant: normal;
  height: 40px;
  line-height: 38px;
  cursor: pointer;
  text-align: left;
  margin: -8px;
  padding: 0;
}

.modal-backdrop {
  background: rgba(0, 29, 77, 0.35);
}

.modal-backdrop.in {
  opacity: 1;
}

.modal-backdrop.fade {
  opacity: 1;
}

.modal-content {
  margin-top: 8em;
}

.modal-content-wrapper,
.center {
  margin-top: 15px;
  text-align: center;
}

.modal-btn-wrapper .btn-talkspace-primary {
  width: 60%;
  max-width: 316px;
  min-width: 220px;
}

.modal-btn-wrapper {
  padding-top: 20px;
  padding-bottom: 30px;
}

.paddedlr-30 {
  padding: 0 30px;
}

.btn-talkspace-primary,
.btn-talkspace-primary:focus {
  -webkit-appearance: none !important;
  cursor: pointer;
  text-align: center;
  border: 0 solid transparent;
  padding: 1.4rem 2rem;
  letter-spacing: 0.1px;
  border-radius: 6px;
  color: #fff;
  background-color: #0fc1a7;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), inset 0 -2px 0 0 rgba(0, 0, 0, 0.22);
  font-size: 18px;
  font-weight: 400;
  width: 316px;
  margin: 0 auto;
}
.btn-talkspace-primary:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0ebea3 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), inset 0 -3px 0 0 rgba(0, 0, 0, 0.22);
}

.all-clients-row .all-clients-heading,
.live-sessions-heading {
  cursor: pointer !important;
}

.all-clients-head {
  padding-top: 10px;
}

a.dashboard-link {
  display: block;
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none !important;
  height: 100% !important;
  width: 100% !important;
}

a.room-reminder-count-text {
  display: block;
  color: #ffffff;
  text-decoration: none;
  height: 100%;
  width: 100%;
}

.table-footer {
  display: inline-block;
  width: 100%;
}

.footer {
  margin-bottom: 30px;
}

.mobile-view-dropdown {
  display: none;
}

.all-room-row-extend {
  height: inherit;
}

.room-reminder-all-clients-container-mobile-only {
  display: none;
}

#search-type-dropdown .caret {
  display: inherit;
}

#search-type-dropdown {
  box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.125);
  border: 1px solid #ccc;
}

.client-wrapper-with-metrics {
  padding: 0 35px 35px;
  height: calc(100vh - 140px);
}

tr.new-room-row td.row-within-rows,
tr.all-room-row td.row-within-rows {
  vertical-align: top !important;
}

@media (max-width: 991px) {
  .room-reminder-container {
    display: flex;
    position: relative;
    top: -97px;
  }

  .mobile-only {
    display: initial;
  }

  .room-reminder-all-clients-container {
    display: none;
  }

  .room-reminder-all-clients-container-mobile-only {
    display: initial;
    position: absolute;
    top: -18px;
    left: 0;
    padding: 2px 15px !important;
  }

  .client-wrapper {
    height: calc(100vh - 68px);
  }

  .client-wrapper-padding {
    padding: 0 20px 20px;
  }

  .client-wrapper-with-metrics {
    padding: 0 20px 20px;
    height: calc(100vh - 140px);
  }

  .mobile-view-dropdown {
    display: inline-block;
  }

  .mobile-view-dropdown,
  .mobile-view-dropdown button,
  .mobile-view-dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
  }

  .mobile-view-dropdown button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  a.dashboard-link {
    display: inline;
  }

  .button-rows {
    padding: 0;
  }

  .room-search {
    padding: 0;
  }

  .room-search .form-group {
    margin: 0;
  }

  .room-search input {
    border-radius: 0;
    border-bottom: none;
    border-top: none;
  }

  .table-hidden {
    display: none;
  }

  table.unstackable {
    border-radius: 0 !important;
  }

  .react-bs-table table td,
  .react-bs-table table th {
    overflow: visible !important;
  }

  .room-arrow {
    display: block;
  }

  .table-tab-container {
    display: flex;
  }

  .all-clients-head {
    padding-top: 0;
  }

  .table-tab {
    color: #555;
    cursor: pointer;
    border-bottom-color: transparent;
    padding: 10px 15px;
  }

  .table-tab.tab-selected {
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom: none;
  }

  .engagement-circle {
    display: inline-block;
    vertical-align: inherit;
    margin-top: -3px;
  }

  .client-engagement .tdCircleRed {
    display: none;
  }

  .ibox-title {
    display: none;
  }

  .ibox thead {
    display: none;
  }

  .ibox-content {
    padding: 0;
  }

  .ibox-content .table {
    border-spacing: 0;
  }

  .new-room-row,
  .all-room-row {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 145px;
    position: relative;
    border-top: none;
  }

  .all-room-row-extend {
    height: 218px;
  }

  .new-room-row:hover,
  .all-room-row:hover {
    background-color: #f5f5f5;
  }

  .row-within-rows {
    border: none !important;
    padding: 2px 15px !important;
  }

  .row-within-rows.client-source,
  .row-within-rows.plan-name {
    font-size: 14px;
    word-wrap: break-word;
    position: absolute;
    overflow: visible !important;
  }

  .row-within-rows.nickname {
    font-size: 18px;
    font-weight: 500;
    position: absolute;
  }

  .client-plan-weekdays,
  .client-renews-date-mobile,
  .client-source-title,
  .client-overdue-mobile {
    display: inline-block;
  }

  .client-renews-date,
  .client-overdue {
    display: none;
  }

  .row-within-rows .client-plan-name {
    height: 18px;
  }

  .row-within-rows.client-renew-date {
    position: absolute;
    top: 86px;
    left: 100px;
    padding: 10 10;
  }

  .row-within-rows.client-overdues {
    position: absolute;
    top: 120px;
    left: 100px;
  }

  .row-within-rows.plan-name {
    top: 32px;
    left: 100px;
    white-space: nowrap;
  }

  .row-within-rows.last-activity {
    position: absolute;
    right: 16px;
    color: grey;
    line-height: 31px !important;
    overflow: visible !important;
  }

  .row-within-rows.last-activity-new-clients {
    top: 87px;
    left: 100px;
    white-space: nowrap;
    font-size: 14px;
    position: absolute;
    overflow: visible !important;
  }

  .row-within-rows.display-time {
    position: absolute;
    right: 110px;
    color: grey;
  }

  .row-within-rows .customer-waiting,
  .row-within-rows.room-status,
  .client-time-to-reply,
  .client-vertical {
    display: none;
  }

  .row-within-rows .selectedDaysOfWeek {
    display: inline-flex;
    color: rgb(51, 51, 51);
    font-size: 14px;
  }

  .row-within-rows.client-source {
    top: 68px;
    left: 100px;
    white-space: nowrap;
  }

  .row-within-rows .client-source-container {
    display: inline-flex;
  }
  .row-within-rows.last-activity-new-clients {
    top: 87;
    left: 100px;
    white-space: nowrap;
    font-size: 14px;
    position: absolute;
    overflow: visible !important;
  }

  .row-within-rows.created-at {
    position: absolute;
    right: 16px;
    color: grey;
    line-height: 31px !important;
  }
}

@media (max-width: 767px) {
  .room-reminder-container {
    display: flex;
    position: relative;
    top: -97px;
  }

  .mobile-only {
    display: initial;
  }

  .room-reminder-all-clients-container {
    display: none;
  }

  .room-reminder-all-clients-container-mobile-only {
    display: initial;
    position: absolute;
    top: -18px;
    left: 0;
    padding: 2px 15px !important;
  }

  .client-wrapper {
    margin: 0;
  }

  .clients-options {
    margin-bottom: 1em;
  }

  .table-hidden {
    display: none;
  }

  .react-bs-table table td,
  .react-bs-table table th {
    overflow: visible !important;
  }

  .room-arrow {
    display: block;
  }

  .table-tab-container {
    display: flex;
  }

  .table-tab {
    color: #555;
    cursor: pointer;
    border-bottom-color: transparent;
    padding: 10px 15px;
  }

  .table-tab.tab-selected {
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom: none;
  }

  .client-engagement .tdCircleRed {
    display: none;
  }

  .ibox-title {
    display: none;
  }

  .ibox thead {
    display: none;
  }

  .ibox-content {
    padding: 0;
  }

  .ibox-content .table {
    border-spacing: 0;
  }

  .all-clients-row {
    width: 100vw !important;
  }

  .new-room-row,
  .all-room-row {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 145px;
    position: relative;
    border-top: none;
  }

  .all-room-row-extend {
    height: 218px;
  }

  .new-room-row:hover,
  .all-room-row:hover {
    background-color: #f5f5f5;
  }

  .row-within-rows {
    border: none !important;
    padding: 2px 15px !important;
  }

  .row-within-rows.client-source,
  .row-within-rows.plan-name {
    font-size: 14px;
    word-wrap: break-word;
    position: absolute;
  }

  .row-within-rows.nickname {
    font-size: 18px;
    font-weight: 500;
    position: absolute;
  }

  .client-plan-weekdays,
  .client-renews-date-mobile,
  .client-source-title,
  .client-overdue-mobile {
    display: inline-block;
  }

  .client-renews-date,
  .client-overdue {
    display: none;
  }

  .row-within-rows .client-plan-name {
    height: 18px;
  }

  .row-within-rows.client-renew-dates {
    position: absolute;
    top: 86px;
  }

  .row-within-rows.client-overdues {
    position: absolute;
    top: 120px;
  }

  .row-within-rows.plan-name {
    top: 32px;
  }

  .row-within-rows.last-activity {
    position: absolute;
    right: 16px;
    color: grey;
    line-height: 31px !important;
    overflow: visible !important;
  }

  .row-within-rows.display-time {
    position: absolute;
    right: 110px;
    color: grey;
  }

  .client-time-to-reply {
    color: red !important;
  }

  .row-within-rows .customer-waiting,
  .row-within-rows.room-status,
  .client-time-to-reply,
  .client-vertical {
    display: none;
  }

  .row-within-rows .selectedDaysOfWeek {
    display: inline-flex;
    color: rgb(51, 51, 51);
    font-size: 14px;
  }

  .row-within-rows.client-source {
    top: 68px;
  }

  .row-within-rows .client-source-container {
    display: inline-flex;
  }

  .row-within-rows.created-at {
    position: absolute;
    right: 16px;
    color: grey;
    line-height: 31px !important;
  }
}

@media (max-width: 375px) {
  .client-wrapper-padding {
    padding: 0 10px 10px;
  }

  .client-wrapper-with-metrics {
    padding: 0 10px 10px;
  }
}
