.account-grid {
  max-width: 1014px;
  margin-top: 20px;
}

.ts-title-green {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: -0.24px;
  color: #007e73;
}

.ts-text-light-gray {
  font-size: 14px;
}

.ts-text-light-gray,
.ts-text-sm-light-gray {
  color: #979797;
  letter-spacing: 0;
  font-weight: normal;
}

.ts-text-dark-gray-bold {
  font-size: 14px;
  color: #222f2d;
  font-weight: 500;
  letter-spacing: 0;
}

.panel-body {
  margin: 30px 40px;
  padding: 0px;
}

/* Profile Styles */
.ts-my-account-panel {
  background-color: #fcfcfc;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 30px 40px;
}

.ts-profile-sm-muted-label {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #979797;
}

.ts-profile-label {
  color: #4a4a4a;
}

.ts-font-black,
.ts-profile-label {
  font-size: 16px;
  margin-left: 10px;
  padding: 6px;
}

.ts-profile-name {
  color: #222f2d;
  font-size: 25px;
  font-weight: 700;
}

.ts-profile-first-name {
  word-wrap: break-word;
}

.ts-profile-text {
  font-size: 16px;
  color: #314d5c;
  letter-spacing: -0.17px;
}

.ts-profile-text-green {
  color: #007e73 !important;
  letter-spacing: -0.17px;
  font-size: 16px;
}

.ts-profile-text-muted {
  font-size: 16px;
  color: #b8b8b8;
  letter-spacing: -0.17px;
}

.input-sm {
  height: 30px;
  line-height: 1.5;
  width: 100px;
}

.ts-profile-text-padding {
  word-wrap: break-word;
  margin: none !important;
}

.ts-profile-bio {
  font-size: 16px;
  letter-spacing: -0.16px;
  color: #979797 !important;
  max-width: 700px;
  word-wrap: break-word;
}

.ts-profile-textarea {
  height: 154px !important;
}

.bio-textarea {
  margin-bottom: 0px;
  resize: none;
  max-width: 700px;
}

.ts-text-invalid {
  font-size: 14px;
  color: rgba(255, 0, 117, 0.8);
  letter-spacing: -0.15px;
}
.ts-text-margin {
  margin: 4px 0 0;
}

.business-hours-time-slot > .ts-text-sm-light-gray {
  font-size: 12px;
}

.business-hours-time-slot > .ts-text-dark-gray-bold {
  display: block;
}

.invalid-field {
  border: 1px solid rgba(255, 0, 117, 0.8);
}

.form-row-container {
  margin-bottom: 10px;
  padding: 0px;
}

/* custom rules for toast */
.toastify-content {
  bottom: 0px !important;
  background: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.toastify--bottom-right {
  padding: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  min-height: 0px !important;
}

.toaster {
  color: #fff;
  padding: 12px;
  font-size: 16px;
}

.toaster-success {
  background: #09b198;
}

.toaster-warn {
  background: #ea9436;
}

.toaster-error {
  background: #e8006b;
}

/* Availability Component */
#businessHoursTable {
  border-collapse: separate;
  border: none;
  margin: 0;
}

#businessHoursTable td {
  padding: 0px !important;
}

.business-hours-time-slot {
  width: 130px !important;
}

#businessHoursTable th {
  padding: 0px !important;
}

#businessHoursTable thead,
#businessHoursTable tbody,
#businessHoursTable td,
#businessHoursTable tr,
#businessHoursTable th {
  border: none !important;
}

.form-control {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.form-control[disabled] {
  border: 1px solid #eee;
  box-shadow: none;
}
.form-control:focus {
  border-color: rgba(28, 199, 169, 0.8) !important;
  box-shadow: 0 1px 1px rgba(28, 199, 169, 0.075) inset, 0 0 8px rgba(28, 199, 169, 0.6) !important;
  outline: 0 !important;
}
.form-control.invalid:not([disabled]) {
  border-color: rgba(255, 0, 117, 0.8);
  box-shadow: none;
  outline: 0;
  -webkit-box-shadow: none;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.business-hours-time-slot-empty-container,
.business-hours-disabled-time-slot-empty-container {
  height: 50px;
  width: 80px;
  background: #fff;
  border: 0 solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.22);
  display: inline-block;
  margin-bottom: 4px;
}

.business-hours-disabled-time-slot-selected-container {
  height: 50px;
  width: 80px;
  background: url(./Availability/checkmark-icon2x.png) center center no-repeat #d8d8d8;
  border-radius: 5px;
  display: inline-block;
  background-size: 22px 19px;
  margin-bottom: 4px;
}
.business-hours-time-slot-selected-container {
  width: 80px;
  height: 50px;
  background: url(./Availability/checkmark-icon2x.png) center center no-repeat #007e73;
  border-radius: 5px;
  display: inline-block;
  background-size: 22px 19px;
}
.business-hours-time-slot-day-row-container {
  background: #c6f4ed !important;
  -webkit-transition: background 0.3s ease-in-out;
  -o-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.business-hours-hover-time-slot {
  border: 1px solid #007e73;
  background: #fff;
}
.clinical-information-text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: -0.15px;
  font-weight: 400;
}
.clinical-info-table {
  border: none;
}
.clinical-info-table > tbody > tr > td {
  background: #f6f6f6;
}
.availability-days .therapist-item-token,
.availability-days li {
  text-transform: capitalize;
}
/*tooltips*/
.tooltip.ts-tooltip .tooltip-inner {
  background: #314d5c;
  box-shadow: 3px 5px 8px 0px rgba(0, 113, 173, 0.2);
  border-radius: 7px;
  text-align: left;
  padding: 12px;
  font-size: 14px;
}

#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner {
  max-width: 370px;
}
#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner p.tooltip-title {
  letter-spacing: -0.16px;
  font-weight: 500;
  color: #fff;
}
#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner p.tooltip-title,
#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner p.shift {
  margin-left: 0px;
}
#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner p.tooltip-title,
#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner div.response-schedule {
  padding-bottom: 10px;
}
#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner p {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-left: 5px;
  color: #bfcfd7;
}
#ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner p span {
  font-weight: bold;
}

.tooltip.right.ts-tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #314d5c;
}

.clinical-info-panel {
  border: none;
}
.clinical-info-panel .panel-heading {
  padding: 0px;
}
.clinical-info-panel .panel-title a {
  display: block;
  padding: 12px;
}
.clinical-info-panel .panel-body {
  margin: 0px;
  padding: 12px;
}
.show-panel {
  opacity: 1;
  transition: 1s opacity, 0.5s height;
}
.hidden-panel {
  opacity: 0;
  height: 1px;
  transition: 1s opacity, 0.5s height;
}
@media (max-width: 991px) {
  a.business-hours-disabled-time-slot-empty-container,
  a.business-hours-disabled-time-slot-selected-container,
  a.business-hours-time-slot-empty-container,
  a.business-hours-time-slot-selected-container,
  div.business-hours-disabled-time-slot-empty-container,
  div.business-hours-disabled-time-slot-selected-container,
  div.business-hours-time-slot-empty-container,
  div.business-hours-time-slot-selected-container {
    width: 40px;
    height: 40px;
  }

  .ts-profile-label {
    margin-bottom: 4px;
  }
  .ts-profile-sm-muted-label.profile-bio {
    color: #4a4a4a;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
  }

  .ts-my-account-panel .table tr td {
    background-color: transparent !important;
  }
  .table.mobile-responsive-table {
    border: none;
  }
  .table.mobile-responsive-table > thead > tr > th {
    border: none;
  }
  .table.mobile-responsive-table > tbody > tr > td {
    margin: 2px;
    padding: 0px;
    width: 40px;
    border: none;
    background-color: none;
  }
  .col-md-10.business-hours-responsive-table {
    padding-left: 0px;
  }
  .table {
    border-collapse: separate;
    border-spacing: 4px;
  }
  .table-responsive {
    border: none;
  }
  .business-hours-responsive-weekday {
    margin-left: 4px;
  }
}

@media (max-width: 767px) {
  .account-grid {
    margin-left: 0px;
    margin-right: 0px;
  }
  .col-xs-12 {
    padding: 0px;
  }
  .ts-profile-name {
    word-wrap: break-word;
  }
  .ts-edit-button {
    margin-right: -2px;
  }
  .ts-panel-title {
    margin-left: -1px;
  }
  .ts-title-green {
    text-align: center;
  }
  .ts-profile-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .row.section-title {
    margin-left: 0px;
    margin-right: 0px;
  }
  .col-md-12.ts-my-account-template {
    padding-left: 0px;
    padding-right: 0px;
  }
  #businessHoursTable {
    border-spacing: 2px;
  }
  .logoWrap img {
    width: 100%;
    max-width: 350px;
  }
  #ResponseScheduleTooltip.tooltip.ts-tooltip .tooltip-inner p {
    font-size: 11px;
    line-height: 15px;
  }

  .panel-body {
    margin: 30px 10px;
    padding: 0px;
  }
}

/*Lu CSS*/
.bootstrap-tokenizer.form-control {
  padding: 4px 6px 4px 4px;
  min-height: 34px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.bootstrap-tokenizer.form-control.focus {
  box-shadow: 0 1px 1px rgba(136, 127, 234, 0.075) inset, 0 0 8px rgba(136, 127, 234, 0.6);
  outline: 0 !important;
}
.therapist-item-token {
  display: inline-flex;
  font-weight: 300;
  letter-spacing: 0.7px;
  color: #fff;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background: #887fea;
  padding: 0 4px;
  margin-top: 2px !important;
  margin-bottom: 1px !important;
  margin-right: 2px !important;
  height: 21px;
}
.therapist-token-close-button {
  color: #544bc4;
  font-weight: 700;
  font-size: 17px;
  margin-left: 8px;
  cursor: pointer;
  padding: 0 2px;
  line-height: 19px;
}
.typeahead-disabled .therapist-item-token {
  background-color: #d8d8d8;
}
.typeahead-disabled .therapist-token-close-button {
  color: #bababa;
}
.typeahead-disabled .bootstrap-tokenizer-input {
  display: none !important;
}
.typeahead-field .bootstrap-typeahead .dropdown-menu > li > a:hover {
  background-color: #887fea !important;
  color: #fff;
}

.bootstrap-typeahead-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 11px;
}
.bootstrap-typeahead-menu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.ts-panel-title i {
  font-size: 20px;
  color: #007e73;
}

.vacation-text {
  font-size: 14px;
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: -0.17px;
  content: '\f073';
  font-family: FontAwesome;
}

.vacation-container-disabled {
  display: inline-block;
  background: #eee;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  padding: 6px;
  width: 130px;
  color: #b8b8b8;
}

.to-span {
  margin-top: 6px;
}

.delete-btn,
.end-btn {
  margin-right: 5px !important;
}

.disabled-picker {
  background: #d8d8d8;
  border-radius: 3px;
}

.update-capacity-warning {
  padding-bottom: 12px;
}
/*Lu CSS*/
/*password strength indicator css*/
.week {
  color: #dd0561;
}

.soSo {
  color: #f0af00;
}

.strong {
  color: #8dc7b6;
}

.veryStrong {
  color: #73c0ad;
}

.strengthIndicator {
  margin-top: 5px;
}
