.ts-component-accordion-container .accordion-menu-item:hover {
  cursor: pointer;
}

.ts-component-accordion-container .accordion-menu-item {
  font-weight: 400;
  font-size: 15px;
  color: #222F2D;
  line-height: 19px;
  letter-spacing: -0.21px;
  border-bottom: 1px solid #D8D8D8;
  padding:0px 0px 6px 1px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ts-component-accordion-container .accordion-menu-item.accordion-link {
  margin: 10px 0;
}

.accordion-menu-item .angle-right {
  background: url('../../Icons/arrow-right.svg') no-repeat;
  background-size: 100%;
  margin-top: 3px;
  width: 8px;
  float: right;
}