.ui.tabular.menu .active.item {
  width: 25% !important;
  font-family: 'Roboto';
  font-size: 15px;
  margin-bottom: 0 !important;
  border-top: 0 !important;
  border-color: white !important;
  color: black !important;
}

.ui.tabular.menu .item {
  width: 25% !important;
  font-family: 'Roboto';
  font-size: 15px;
  color: #c4c4c4 !important;
  background: white !important;
}

.ui.menu a.item:hover {
  color: #c4c4c4 !important;
}

.ui.tabular.menu .item:hover {
  background-color: none;
}

.ui.menu .active.item:hover {
  color: rgba(0, 0, 0, 0.95) !important;
}

.journey-icon .case-icon .clinical-icon .notes-icon {
  margin-bottom: 1em;
}

.ui.tabular.menu .item:hover {
  background-color: white;
}

.journey-icon-svg {
  margin-bottom: 2px;
  margin-left: 23px;
}

.notes-icon-svg {
  margin-left: 15px;
  margin-bottom: 4px;
}

.clinical-icon-svg {
  margin-left: 18px;
  margin-bottom: 4px;
}

.case-icon-svg {
  margin-bottom: 6.1px;
  margin-right: 14px;
  margin-left: 12px;
}

.crm-tabs-container {
  display: flex;
  flex-direction: row;
  height: 66px;
  align-items: center;
  padding: 0;
  box-shadow: 0px 1px 3px -2px #d3d3d3;
  border-bottom: 0.5px solid #d3d3d3;
  transition: 0.3s;
}

.crm-tabs-icon-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.crm-tabs-link {
  display: flex;
  flex: 1;
}

.crm-tabs-icon-text {
  margin-top: 2px;
  color: #c4c4c4;
  font-weight: 500;
}

.crm-tabs-icon-text,
.ts-component-accordion-container-title,
.faux-placeholder,
.ts-component-submenu .title,
.accordion-menu-item {
  user-select: none;
}

/* clinical and case css */

.ts-crm-panel {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 355px;
  align-items: center;
}

.ts-crm-panel::-webkit-scrollbar {
  visibility: hidden;
}
.ts-crm-panel::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.ts-crm-panel::-webkit-scrollbar-track {
  visibility: hidden;
}
.ts-crm-panel:hover::-webkit-scrollbar {
  visibility: visible;
}
.ts-crm-panel:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.ts-crm-panel:hover::-webkit-scrollbar-track {
  visibility: visible;
}

.note-search-item {
  display: block;
  font-size: 14;
  line-height: 19px;
  max-height: 38px;
  color: #3a3a3a;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}