.treatment-planner-multiselect-container {
  display: flex;
  flex-direction: column;
}

.treatment-planner-multiselect.grayed {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.treatment-planner-multiselect.grayed,
.treatment-planner-multiselect.grayed .ts-component-multi-select {
  pointer-events: none;
}
.treatment-planner-multiselect.grayed .list-item-dropdown {
  width: 1px;
  position: relative;
  right: 18px;
  top: 8px;
}
.treatment-planner-multiselect.grayed .ts-component-multi-select .ui.label {
  background-color: #c7c7c7 !important;
  pointer-events: none;
}

.treatment-planner-multiselect.grayed .ts-component-multi-select .ui.label .delete:before {
  border-color: #fff !important;
  color: #fff !important;
}

.treatment-planner-multiselect .list-item-dropdown {
  pointer-events: all;
}

.treatment-planner-goals-title,
.treatment-planner-objectives-title,
.treatment-planner-interventions-title {
  font-size: 12px;
  color: #979797;
}

.treatment-planner-goal,
.treatment-planner-objective,
.treatment-planner-intervention {
  font-size: 14px;
  color: #000;
  margin-bottom: 4px;
}

.treatment-planner-objective {
  font-weight: 600;
}

.treatment-planner-interventions-container {
  border-left: 2px solid #f3f2f4;
}

.treatment-planner-objective > .checklist-item > .checkbox > label {
  font-weight: 600;
}

.treatment-planner-interventions-container {
  border-left: 5px solid #f3f2f4;
  padding-left: 10px;
}

.treatment-planner-objective-container .checklist-item .checkbox > label,
.treatment-planner-interventions-container .checklist-item .checkbox > label {
  position: unset;
}

.treatment-planner-objective-container .checklist-item .checkbox,
.treatment-planner-interventions-container .checklist-item .checkbox {
  position: relative;
  padding-left: 0px !important;
  padding-right: 25px !important;
  margin-top: 0px;
}

.treatment-planner-objective-container .checklist-item .checkbox label::before {
  margin-left: auto;
  right: 0;
  top: 0px;
  position: absolute;
}

.treatment-planner-interventions-container .checklist-item .checkbox label::before {
  margin-left: auto;
  right: 0;
  top: 0px;
  position: absolute;
}

.treatment-planner-intervention .checked label {
  text-decoration: line-through;
  color: #979797;
}

.treatment-planner-objective-container > div > .checklist-item > .checkbox > label::after {
  margin-left: auto;
  right: 0;
  top: 0px;
  position: absolute;
  padding-left: 4px;
  padding-top: 2px;
}

.treatment-planner-interventions-container > div > .checklist-item > .checkbox > label::after {
  margin-left: auto;
  right: 0;
  top: 0px;
  position: absolute;
  padding-left: 4px;
  padding-top: 2px;
}

.treatment-planner-objective-container .checklist-item .checkbox.disabled label,
.treatment-planner-objective-container .checklist-item .checkbox.disabled label {
  opacity: unset;
}

.treatment-planner-objective-container .checklist-item .checkbox.disabled,
.treatment-planner-interventions-container .checklist-item .checkbox.disabled {
  pointer-events: none;
}

.treatment-planner-objective-container
  .checklist-item
  .checkbox-green.disabled.checked
  label::before,
.treatment-planner-objective-container
  .checklist-item
  .checkbox-green.disabled.checked
  label::before {
  background-color: #007e73;
  border-color: #007e73;
}

.treatment-planner-objective-container .checklist-item .checkbox-green.disabled label::before,
.treatment-planner-objective-container .checklist-item .checkbox-green.disabled label::before {
  background-color: #fff;
  border-color: #cccccc;
}
