.ts-component-multi-select.up .faux-placeholder {
  color: #D8D8D8
}

.treatment-goal-input .has-lock-icon > div:first-of-type {
  margin-top: 22px;
}

.treatment-goal-input .has-lock-icon .lock-icon {
  top: 0 !important;
}