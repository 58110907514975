.modal-container {
  position: relative;
}
.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}
.modal-title {
  text-align: center;
  font-size: 24px;
}
.modal-body {
  text-align: center;
}
.modal-footer {
  margin: 0 auto;
}
.ts-btn {
    color: #fff;
    background-color: #0FC1A7;
    border-color: #009B8D;
}
.ts-btn:focus,
.ts-btn.focus {
    color: #fff;
    background-color: #009B8D;
    border-color: #255625;
}
.ts-btn:hover {
    color: #fff;
    background-color: #009B8D;
    border-color: #398439;
}
.ts-btn:active,
.ts-btn.active,
.open > .dropdown-toggle.ts-btn {
    color: #fff;
    background-color: #009B8D;
    border-color: #398439;
}

.ts-btn-default {
    color: #222F2D;
    background-color: transparent;
    border-color: #009B8D;
}

.btn-ts-default {
  background-color: transparent;;
  border: 1px solid #0FC1A7;
  border-radius: 6px;
}

.btn-ts-default:hover {
  color: #fff;
  background-color: #009B8D;
  border-color: #009B8D;
}

.btn-ts-default:focus, .btn-ts-default.focus {
  color: #fff;
  background-color: #009B8D;
  border-color: #009B8D;
}


.btn-ts-green {
  color: #fff;
  background-color: #0FC1A7;
  border-color: #0FC1A7;
  border-radius: 6px;
}

.btn-ts-green:hover {
  color: #fff;
  background-color: #009B8D;
  border-color: #009B8D;
}

.btn-ts-green:focus, .btn-ts-green.focus {
  color: #fff;
  background-color: #009B8D;
  border-color: #009B8D;
}

.btn[disabled] {
  background-color: #DCDCDC;
  border-color: #DCDCDC;
}

.keep-me {
  font-size: 18px;
  padding: 12px 80px;
  display: block;
  margin: 0 auto;
}
