body {
    background-color: #FFFFFF;
    font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialised;
}

.ts-btn {
    color: #fff;
    background-color: #0FC1A7;
    border-color: #009B8D;
}

.login-container{
    padding-top:200px;
}

.logoWrap{
    padding-top: 25px;
    text-align: center;
}
.logoWrap img {
  width: 350px;
}

.login-error {
  font-weight: 700;
}

.ts-btn:hover,
.ts-btn:active,
.ts-btn.active,
.open > .dropdown-toggle.ts-btn {
    color: #fff;
    background-color: #009B8D;
    border-color: #398439;
}

.loginFormWrap{
    margin: 30px auto 0;
    width: 365px;
}

.actionText{
    font-size: 19px;
    color: rgb( 104, 105, 106 );
    font-weight: 400;
    font-style: normal;
}

.login-error {
    padding-bottom: 12px;
}

.forgotPassword{
    margin-top:5px;
    cursor: pointer;
}

#ForgotPasswordTooltip .tooltip-inner{
    max-width: 250px;
}

@media only screen and (min-width : 1200px) {
}

@media only screen and (max-width : 1199px) {
}

@media (max-width: 991px) {
}

/*******************************************
******Responsive***************************
******************************************/
@media (max-width: 767px) {
}
@media (max-width: 480px) {
}

@media (max-width: 374px) {
}
