.ts-component-dialog-container {
  width: 344px;
  border: 1px solid #E8006B;
  border-radius: 5px;
  padding: 12px 20px 12px 20px;
  margin-bottom: 15px;
  transition: 0.2s;
  display: block;
}

.ts-component-dialog-title {
  font-size: 15px;
  margin: 10px 0 10px 6px;
  display: flex;
}
.ts-component-dialog-text {
  font-size: 14px;
  color: #747474;
  padding: 0px 0px 12px 6px;
}
