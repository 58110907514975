.checklist-editor {
  display: flex;
  margin-top:0px;
  margin-bottom:5px;
}
.checklist-editor textarea {
  margin-top: 8px;
  height: 78px;
  resize: none;
}

.checklist-editor .checkbox {
  padding-left: 20px;
}
.checklist-editor .checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}
.checklist-editor .checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #FFF;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checklist-editor .checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 4px;
  padding-top: 3px;
  font-size: 12px;
  color: #555555;
}
.checklist-editor .checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
}

.checklist-editor .checkbox.checked label::after {
  font-family: "FontAwesome";
  content: "\f00c";
}

.checklist-editor .checkbox.disabled label {
  opacity: 0.65;
}
.checklist-editor .checkbox.disabled label::before {
  background-color: #EEE;
  cursor: not-allowed;
}
.checklist-editor .checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checklist-editor .checkbox.checkbox-inline {
  margin-top: 0;
}

.checklist-editor .checkbox-green.checked label::before {
  background-color: #0FC1A7;
  border-color: #0FC1A7;
}
.checklist-editor .checkbox-green.checked label::after {
  color: #FFF;
}

.checklist-editor .checkbox-green.disabled label::before{
  opacity: 1;
  background-color: #FFF;
  border-color: #FFF;
}