.spinner-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: Arial;
  }

.spinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
  }
  
  .spinner > div {
    width: 50px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
  }

  .bt-spinner {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #fff;
    border-color: #009688;
    border-top-color: #fff;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }