.reactions-component {
  color: #8e99a6;
  font-size: 12px;
  flex-grow: 1;
  margin-left: 60px;
  height: 35px;
  margin-top: -10px;
  position: relative;
}

.reactions-component div {
  margin-top: 10px;
  cursor: default;
}

.reactions-container {
  position: absolute;
  display: flex;
  height: 55px;
  bottom: 30px;
  background: #fff;
  border: 0.9px solid #efefef;
  left: -32px;
  border-radius: 40px;
  box-shadow: 0px 5px 18px rgba(50, 64, 78, 0.24);
  -webkit-box-shadow: 0px 5px 18px rgba(50, 64, 78, 0.24);
  -moz-box-shadow: 0px 5px 18px rgba(50, 64, 78, 0.24);
}

.reactions-component .like,
.reactions-component .dislike {
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.reactions-component .dislike.animate,
.reactions-component .like.animate {
  transition: margin 1s, width 1s, height 1s, background-size 1s;
}

.reactions-component .dislike.animate-init {
  margin-top: -40px;
  margin-left: 50px;
  z-index: 1000;
  background-size: 30px;
  width: 30px;
  height: 30px;
  visibility: hidden;
}

.reactions-component .like.animate-init {
  margin-top: -40px;
  margin-left: -7px;
  z-index: 1000;
  background-size: 30px;
  width: 30px;
  height: 30px;
  visibility: hidden;
}

.reactions-component .like.animate.animate-down {
  margin-top: 10px;
  margin-left: 0px;
  background-size: 20px;
  width: 20px;
  height: 20px;
  visibility: visible;
}

.reactions-component .dislike.animate.animate-down {
  margin-top: 10px;
  margin-left: 0px;
  background-size: 20px;
  width: 20px;
  height: 20px;
  visibility: visible;
}
