.user-badge-container {
  cursor: pointer;
  display: flex;
  min-width: 45px;
}
.userBadge {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  max-width: 35px;
  background: #D8D8D8;
  color: #FFFFFF;
  font-size: 17px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  outline: none !important;
  border-color: transparent;
  transition: 0.2s;
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.07);
  user-select: none;
}
.userBadge .initials {
  margin-top: -2px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.user-badge-name {
  font-size: 15px;
  text-align: center;
  color: #979797;
  display: flex;
  align-items: center;
}
.active .user-badge-name {
  color: #000;
}
.user-badge-container:not(.active):hover > .btn.userBadge {
 background: #B8B8B8;
 border-color: transparent;
 color: #FFFFFF;
}
.user-badge-container:focus > .btn.userBadge,
.user-badge-container:active > .btn.userBadge {
  box-shadow: none;
  outline: none;
  color: #FFFFFF;
  border-color: transparent;
  background: #0FC1A7;
}
.user-badge-container.active > .user-badge-name {
  color: #000;
}
.user-badge-container.active > .userBadge {
  background-color: #0FC1A7;
  color: #FFFFFF;
}
