.multirow-list-item {
  display: flex;
  border-bottom: 1px solid #d3d3d3;;
  padding: 15px 15px;
  align-items: center;
}

.multirow-list-item .index {
  width:35px;
  text-align: left;
  vertical-align: middle;
  height: 100%;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
}

.multirow-list-item .item-data-container {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.multirow-list-item .item-data-container > div {
  color: #b1b1b1; 
}

.multirow-list-item .item-data-container > div:first-child {
  padding-bottom: 10px;
  color: #000000;
}
